/************PAGE SHELL*********/
body {
    font-size: 16px;
    padding: 0;
    margin: 0;
    /*background-color: #ccc;*/
	font-family: Barlow;
}
.report-wrapper {
	padding: 0;
	margin:0;
	background-color: var(--lightest-grey-color);
}
.report-wrapper p, .report-wrapper ul { font-size:1.0rem; clear:both; font-weight:normal;text-align:left; line-height: 1.5; margin-bottom: 0!important; margin-top: 0!important; }
.report-wrapper h1,.report-wrapper h2,.report-wrapper h3,.report-wrapper h4,.report-wrapper h5 { width:100%; text-align:left; margin: 0;	}

/* Page Titles */
.report-wrapper h1 { font-size:2em; font-weight:bold; padding:10px 0px 5px 0px; line-height:1.5; }

/* Section Titles */
.report-wrapper h2 { font-size:1.75em; font-weight:normal; padding:8px 0px 6px 0px; line-height:1.2; }

/* Paragraph Main Titles / Widget Titles */
.report-wrapper h3 { width:100%; font-size:1.25rem; font-weight:normal; padding:6px 0px 0px 0px; }

/* Non-paragraph Titles */
.report-wrapper h4 { font-size:1.2rem; padding-bottom: 0.2rem; }

/* Inline Paragraph Subheads */
.report-wrapper h5 { font-weight: bold;}

.report-wrapper {
	.page-container { 
		width: 100%;
		margin: 0 0 30px 0; // was calc(20px + var(--header-height)) 0 30px;
		position: relative;
		padding: 2.5rem;
		background-color: white;
		z-index: 1;
	}
	.page-header {
		position: relative; // was fixed;
		z-index: 3; // was 2;
		top: 0;
		left: 0;
		width: 100%;
		// height: var(--header-height);
		background-color: var(--theme-primary);
		color: var(--theme-primary-contrast);
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		padding: 0;
	}

	.branding-header-image{
		/*position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 500px;*/
		background-position: bottom right;
		background-repeat: no-repeat;
		background-size: cover;
		/*z-index: 0;*/
	}
	.reportstationary-divider {
		// margin-top: calc(80px + 2rem);
		height: calc(500px + 130px); // was 500px;, update to var(--header-height)
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 0;
	}
	.reportstationary-header-image {
		width: 750px; // was 200px; - only when there is ONE logo is it 750px
	}

	.reportstationary-header-image {
		height: 100%; // was calc(100% - 20px);
		padding: 0; // was 10px;
		margin-left: auto; // was 40px;
		width: 1350px;
		margin: 0 auto;
	}
	.page-header__logo img{
		max-height: 150px;
		max-width: 750px;
		margin: 1.5rem;
	}
	#page-container-centralizer {
		display: flex;
		justify-content: center;
	}

	.modal-container {
		position:fixed; 
		top: 6%; 
		left: 50%; 
		transform: translateX(-50%); 
		width: 96%; 
		max-width: 1350px; 
		box-shadow: 0 0.125rem 0.5rem rgb(0 0 0 / 20%) !important; 
		z-index:999;
	}

	/********************************/

	h1.report-title {
		width: 500px;
		text-transform: uppercase;
		font-size: 3em;
		font-weight: 600;
		line-height: 1em;
		padding: 1em 0 1em 0;
	}
		
	p.report-date {
		padding-bottom: 2em;
		margin: 0;
	}	
		
	h2.section-title {
		font-size: 1.6em;
		border-bottom: 1px solid #CCC;
		padding-top: 0.3em;
		padding-right: 0;
		padding-left: 0;
		padding-bottom: 0.5em;
		border-top: 1px solid #CCC;
		margin: 0 0 1em 0;
		font-weight: 600;
	}
	h3.subheader-title {
		font-size: 1.5em;
		font-weight: 300;
		padding: 0 0 0 0;
		margin: 0 0 0.5em 0;
	}

	h4.paragraph-header {
		font-size: 1.4em;
		padding: 0 0 0 0;margin:0 0 0.3em 0;
	}
	h4.competency-title {
		color: #a233ff;
		font-size: 1.4em;
		padding: 0 0 0 0;margin:0 0 0.3em 0;
	}
	h5.bold-text-header {
		font-size: 1em;
		padding: 0 0 0 0;margin:0.5em 0 0.5em 0;
	}
	p {
		font-size: 1em;
		font-weight: 300;
		padding: 0 0 0.2em 0;margin:0 0 1em 0;
		line-height: 1.2em;
	}


	/************ CONTAINERS ********/	
	.horizontal-container, .vertical-container, .horizontal-wrapping-container {
		display:flex; 
		justify-content: flex-start; 
		padding: 0; 
		margin: 0;  	
		list-style: none; 
		clear:both; 
		position:relative;
		z-index:1;	
	}
	.horizontal-wrapping-container {
		justify-content: space-between;
		flex-flow:column;
		flex-direction:row;
		flex-flow:row wrap;
		clear:both;
	}
	.vertical-container {
		flex-flow:column wrap;	
		clear:both; 
		width:100%;
	}
	.horizontal-wrapping-container.columns-3 > div,
	.horizontal-wrapping-container.columns-2 > div,
	.horizontal-wrapping-container.columns-golden > div {
		width: 100%;
	}

	.up-arrow::before {
		content: "";
		width:0;
		height: 0;
		position:absolute;
		top:-40px;
		left:calc(50% - 20px);
		border-width:20px;
		border-style: solid;
		border-color: transparent transparent #F2F4F5 transparent;
		z-index: 2;
	}
	.up-arrow::after {
		content: "";
		width:0;
		height: 0;
		position:absolute;
		top:-42px;
		left:calc(50% - 21px);
		border-width:21px;
		border-style: solid;
		border-color: transparent transparent silver transparent;
	}

	/********************************/


	/************ WIDGETS ***********/
	/* IMAGE */
	.vertical-container .image-widget img,
	.horizontal-wrapping-container .image-widget img {
		width: 100%;
	}
	.horizontal-container .image-widget {
		background-size: contain; 
		background-repeat: no-repeat;
		position: relative;
	}

	/* HTML */
	.html-widget {
		flex-flow:column wrap;
		height:auto;
		position: relative;
	}

	/* DONUT */
	.donut-widget {
		text-align:center;
		width:100%;
		position: relative;
	}
	.donut-widget .output { 
	font-size:8.9vw; 
	line-height: 1; 
	font-weight: bold; 
	position: absolute; 
	top:50%;
	left:50%;
	transform:translate(-50%,-60%); 
	}

	/* BUBBLE */
	.bubble-chain-widget, .bubble-container {
		position: relative;
	}
	.bubble-container img { width: 100%;height: auto; }
	.bubble-chain-10 {
		position:absolute;
		width:102%;
		top: 0;
		left: -1%;
		height: 100%;
	}
	.bubble-chain-10 div {
	display: inline-block;
	width: 10%;
	height: 100%;
	font-weight: bold;
	position: relative;
	}
	.bubble-chain-10 div span { 
		position:absolute;
		left: 50%;
		top: 43%;
		transform: translate(-50%, -50%);
	}
	.bubble-chain-10 div.active span { 
		color: #FFF;
		z-index: 2;
	}
	.bubble-chain-10 div.active img {
		position:absolute;
		height: 100%;
		left: 0;
		top:-1.3%;
		z-index: 1;
	}

	/* BAR HORIZONTAL */
	.bar-horizontal-widget, .bar-container {
		position: relative;
	}
	.bar-10 {
		position:absolute;
		width:102%;
		top: 0;
		left: -1%;
		height: 100%;
	}
	.bar-10 div {
	display: inline-block;
	width: 10%;
	height: 100%;
	font-weight: bold;
	position: relative;
	}
	.bar-10 div span { 
		position:absolute;
		left: 50%;
		top: 43%;
		transform: translate(-50%, -50%);
	}
	.bar-10 div.active span { 
		color: #FFF;
		z-index: 2;
	}


	/*********** MEDIA QUERIES***********/
	@media screen and (min-width:801px){
		
		.horizontal-wrapping-container.columns-3 > div {width: 33.3%;}
		.horizontal-wrapping-container.columns-2 > div {width: 50%;}

		.horizontal-wrapping-container.columns-golden > div:first-child { width: 33.3%; margin-right: 1.5rem; }
		.horizontal-wrapping-container.columns-golden > div:last-child { width: calc(66.6% - 1.5rem); }

		.donut-widget img {width:80%;}
		.donut-widget .output { font-size:600%; }

		/* Page Titles */
		.report-wrapper h1 { font-size:2.25em; font-weight:bold; padding:15px 0px 5px 0px; line-height:1.5; }

		/* Section Titles */
		.report-wrapper h2 { font-size:2em; font-weight:normal; padding:10px 0px 10px 0px; line-height:1.2; }

		/* Paragraph Main Titles / Widget Titles */
		.report-wrapper h3 { width:100%; font-size:1.35rem; font-weight:normal; padding:6px 0px 0px 0px; }


	}
	@media screen and (min-width:1350px){

		.page-container { 
			width: calc(1350px - 5rem);
		}

		/* Page Titles */
		.report-wrapper h1 { font-size:3em; font-weight:600; padding:0 0 20px 0; line-height:1.15; }

		/* Section Titles */
		.report-wrapper h2 { font-size:2.2em; font-weight:normal; padding:15px 0px 15px 0px; line-height:1.2; }

		/* Paragraph Main Titles / Widget Titles */
		.report-wrapper h3 { width:100%; font-size:1.5rem; font-weight:normal; padding:6px 0px 0px 0px; }

	}
}